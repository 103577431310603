import dayjs from 'dayjs'

interface IFormatDate {
  value: string | Date
  format?: string
}

export default function formatDate({ value, format = 'M/D/YY' }: IFormatDate) {
  return dayjs(value).format(format)
}
